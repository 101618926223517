var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tab-main-container" }, [
    _c("div", { staticClass: "headline" }, [_vm._v("友達一覧")]),
    _c("div", { attrs: { id: "main-content" } }, [
      _vm.friendList.length == 0
        ? _c("div", { attrs: { id: "search-results" } }, [
            _vm._v("友達登録しているユーザーはいません")
          ])
        : _vm._e(),
      _vm.friendList.length > 0
        ? _c(
            "div",
            { attrs: { id: "table" } },
            _vm._l(_vm.friendList, function(item, index) {
              return _c(
                "div",
                { staticClass: "table-row", attrs: { value: item } },
                [
                  _c("div", { staticClass: "badgeIcon" }, [
                    !item.iconPath == ""
                      ? _c("img", {
                          attrs: { src: _vm.createImgPath(item.iconPath) }
                        })
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "name",
                      on: {
                        click: function($event) {
                          return _vm.callRouterInfo(item)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                ]
              )
            }),
            0
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }