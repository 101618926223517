<template lang="pug">
  .tab-main-container
    .headline 友達一覧
    #main-content
      #search-results(v-if="friendList.length==0") 友達登録しているユーザーはいません
      #table(v-if="friendList.length>0")
        .table-row(v-for="(item,index) in friendList" :value="item")
          .badgeIcon
            img(:src="createImgPath(item.iconPath)" v-if="!item.iconPath==''")
          .name(@click="callRouterInfo(item)") {{item.name}}
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      friendList:[]
    }
  },
  created() {
    this.createdProcess()
  },
  // メソッド
  methods: {
    async createdProcess(userId){
      this.$parent.friendList.forEach((userId) => {
        this.getUserInfo(userId)
      })
    },
    async getUserInfo(userId){
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-search-userlist/friendinfo'
      await axios.get(jsonUrl, {
        params: {
          'userId': userId
        },
        headers: {
          'Authorization': this.$user.idToken
        }
      })
      .then(res => {
        if(res.data.length > 0){
          this.friendList.push(res.data[0])
        }
      })
    },
    callRouterInfo(item){
      this.$router.push('/myPage/'+ item.id + '/userInfoTop')
    },
    createImgPath(path){
      return this.getStatusIconPath(path)
    },
  },
  // 算出プロパティ
  computed: {
  },
  // コンポーネント
  components: {
  }
}
</script>

<style lang="stylus" scoped>
#main-content
  #search-results
    margin 1rem
  #table
    margin-bottom 3rem
    .table-row
      height 50px
      display flex
      justify-content center
      align-items center
      width 100%
      background #FFF
      color #374047
      margin-bottom 2px
      cursor pointer
      .badgeIcon
        width 25%
        text-align center
        img
          width 40px
          height 40px
          border-radius 50%   
      .name
        width 75%
        font-weight 500
        text-decoration underline
        padding-left 0.5rem
    .table-row:hover
      background #2d8fd5
      .name
        color #FFF
      .badgeIcon
        img
          filter brightness(120%)
</style>
